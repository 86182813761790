.wrapper {
  margin-top: 45px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.slider {
  width: 100%;
  height: 120px;
  position: relative;
  background: white;
  display: flex;
  overflow: hidden;
  padding-bottom: -100px;
}

.slide {
  height: 100px;
  display: flex;
  align-items: center;
  animation: slideshow 60s linear infinite;
}

.slide-reverse {
  height: 100px;
  display: flex;
  align-items: center;
  animation: slideshow-reverse 60s linear infinite;
}

.slide img {
  padding: 0 25px 0 25px;
}

.slide-reverse img {
  padding: 0 20px 0 20px;
}

@keyframes slideshow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideshow-reverse {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slider::before,
.slider::after {
  height: 200px;
  width: 200px;
  position: absolute;
  content: "";
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 30%);
  z-index: 2;
}

.slider::before {
  left: 0;
  top: 0;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
