.container {
  max-width: 300px;
  min-width: 300px;
}

.card {
  height: 23rem;
}

.card-content h2,
h3,
caption {
  text-align: center;
}

@media (any-hover: hover) and (any-pointer: fine) {
  .card-content {
    background-color: white;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    transition: transform 200ms ease-out;
  }

  .container:hover,
  .container:focus-within {
    transform: scale(1.02);
    transition: transform 200ms ease-in;
  }

  .card:hover .card-content,
  .card:focus-within .card-content {
    transform: translateY(0);
    transition: transform 200ms ease-in;
  }
}
