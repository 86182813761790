.services-container {
  display: flex;
  padding-top: 8px;
  gap: 60px;
  @media (max-width: 600px) : {
    flex-direction: column;
    text-align: center;
    gap: 0px;
  }
}

.services {
  box-shadow: inset 0 0 0 0 #f39323;
  color: #6f4500;
  padding: 0.56rem;
  line-height: 1;
  text-decoration: none;
  border-radius: 15px;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.services:hover {
  color: #fff;
  box-shadow: inset 200px 0 0 0 #f39323;
}

.services h2 {
  color: #6f4500;
  cursor: pointer;
}
