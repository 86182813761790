.features-container {
  display: flex;
  justify-content: space-around;
  gap: 40px;
  margin: 0 40px 0 40px;
  align-items: center;
}

@media (max-width: 850px) {
  .features-description-container {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 10px;
    align-items: center;
    text-align: center;
  }
}

.reveal {
  position: relative;
  opacity: 0;
  height: 500;
  width: 500;
  max-height: 500px;
  max-width: 500px;
  padding-top: 40px;
}

.reveal.active {
  opacity: 1;
}

.active.fade-left {
  animation: fade-left 0.8s ease-in;
}

.active.fade-right {
  animation: fade-right 0.8s ease-in;
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 0.8;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 0.8;
  }
}
